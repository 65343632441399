"use client"

import { NavLink } from "react-router-dom"
import StickyHeader from "./StickyHeader"
import { useState, useEffect } from "react"

import ProcesoContacto from './ProcesoContacto';
import "../css/soporteit.css"

import imgMainImg from "../img/img_main.jpg"

const services = [
  {
    title: "Soluciones Prontas",
    description: [
      "Nuestros consultores le brindarán asistencia lo más pronto posible. Brindamos soluciones y estrategias dentro de un marco de tiempo establecido ¡Sin Demoras!",
    ],
  },
  {
    title: "Especialistas en Soporte IT",
    description: [
      "Más de 10 años en Guatemala y Estados Unidos brindando servicios de Soporte IT a nuestros clientes de sector público y privado.",
      "Consultores IT certificados por Google, cada uno con su propio conjunto de habilidades únicas.",
      "Seleccionamos un experto en IT en función del escenario de su organización.",
    ],
  },
  {
    title: "Servicio Enfocado y Flexible",
    description: [
      "Analizamos el escenario de su organización en temas de Gestión de Soporte IT y con base a ello brindamos Soluciones viables para su empresa en Guatemala.",
    ],
  },
  {
    title: "Ahorre Tiempo y Dinero",
    description: [
      "Invertir en un proveedor de servicios de alta calidad puede ayudarlo a aumentar el retorno de su inversión (ROI).",
      "Al contar con un aliado estratégico en el Área Informática, toma la sabia decisión de estar preparado para las fallas técnicas en sus dispositivos de informática.",
      "No solo obtiene soluciones rápidas, sino también constantes cursos de acción y conocimiento que le proporcionará acciones preventivas para tomar buenas decisiones relacionadas a gestión de recursos y preservación de su información.",
    ],
  },
  {
    title: "Ventajas ante sus competidores",
    description: [
      "Cuando invierte en un Proveedor de Servicios IT competente, obtiene el beneficio de una operación eficiente de sus ordenadores, servidores y redes. Estos servicios impactan directamente en una mejora en la atención a sus clientes e incrementa la rentabilidad.",
    ],
  },
  {
    title: "Enfóquese en su Giro de Negocio",
    description: [
      "Olvide las fallas técnicas y oriente su operación hacia lo que hace mejor.",
    ],
  },

  {
    title: "Soporte Técnico",
    description: [
      "Asistencias técnicas ilimitadas de forma remota y presencial a sus ordenadores, servidores y dispositivos de red para que su operación funcione de manera eficiente, sin atrasos. Atención en toda Guatemala.",
    ],
  },
  {
    title: "Seguridad Informática",
    description: [
      "¿Quieres mantener tus oficinas alejadas de las amenazas de hackers profesionales? Con la ayuda de nuestros Consultores IT puede ignorar estos ataques dirigidos, a través de nuestros Servicios de Informática Outsourcing y protocolos planificados previamente, reducimos las amenazas que pueda presentar su información."
    ],
  },
  {
    title: "Implementación de Firewall",
    description: [
      "Un Firewall es un buen aliado en cualquier empresa donde existen aquellas personas que utilizan de manera indiscriminada la gran variedad de sitios de internet y a su vez comprometen la integridad y seguridad de la información, es de mencionar que los principales focos de ataques y amenazas son normalmente las diferentes redes sociales, sitios de pornografía, streaming, noticias, entre otros; Todo esto sin olvidar el costo o gasto sombra que representa el personal al momento de permanecer en estos sitios en horario laboral.",
    ],
  },
  {
    title: "Propuestas Integrales",
    description: [
      "Tener a YETI Advisers como aliado estratégico es tener al alcance Soluciones Integrales que apoyen el crecimiento de su organización; esto lo logramos a través de nuestros Planes de Soporte IT Mensuales Sin Contratos que contemplan servicios preventivos, correctivos y cuyo propósito es institucionalizar el Departamento de Informática en su empresa. Atención en toda Guatemala"
    ],
  },
]

const questions = [
  {
    title: "¿Qúe es Soporte IT?",
    content: [
      "El soporte IT es una forma de ayuda técnica proporcionada a negocios, empresas, escuelas o incluso universidades. Una empresa como YETI Advisers brinda ayuda y soporte técnico a las organizaciones para resolver cualquier falla relacionada con los recursos informáticos tales como: Computadoras, Servidores y Dispositivos de Red.",
    ],
  },
  {
    title: "¿Qúe son los Servicios de Informática administrados?",
    content: [
      "Un Servicio de Informática Administrado es cuando una empresa cuenta con el expertise de numerosos escenarios en el área IT y presta el servicio a otra organización de manera outsourcing.",
      "La empresa de IT brinda gestión de recursos de informática, soporte técnico y desarrollo, soluciones en servidores web, entre otros servicios.",
      "Cada vez son más las empresas que deciden contratar una organización outsourcing para gestionar su departamento IT, sustituyendo así a los equipos internos.",
    ],
  },
  {
    title: "¿En qué le ayuda a su Empresa?",
    content: [
      "Los servicios IT ayudan a administrar y supervisar sus recursos informáticos. Podemos ayudarle con la instalación, el mantenimiento y el buen funcionamiento de toda su infraestructura informática (Computadoras, Servidores y Redes Informáticas).",
    ],
  },
  {
    title: "¿Cómo puede un proveedor de Servicios de Informática ayudar a aumentar los ingresos de su negocio?",
    content: [
      "Al contar con una gestión eficiente de recursos tecnológicos en su empresa, se incrementa la ventaja competitiva para su organización, los servicios de atención al cliente satisfactorios dan como resultado mayores ingresos y más recomendaciones para su marca.",
    ],
  },
  {
    title: "¿Por qué es importante una empresa de Servicios de Soporte IT?",
    content: [
      "Una empresa de servicios IT se encargará de la gestión de recursos informáticos de su empresa. De esta manera, los dueños y gerentes de negocios obtienen más tiempo para concentrarse en sus estrategias principales.",
      "Con el soporte IT las 24 horas, los 7 días de la semana, no tiene que preocuparse por fallas en la red o fallas en sus ordenadores. YETI Advisers como proveedor de servicios IT, se encargará de brindarle solución a fallas técnicas, mantenimientos preventivos periódicamente, asesoría en nuevos proyectos de inversión relacionados al área IT y de la mano con estrategias planificadas brindará planes de contingencia para la preservación de su información.", 
    ],
  },
]


const Main = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [isVisible, setIsVisible] = useState(true)
  const [activeService, setActiveService] = useState(0)
  const [activeQuestion, setActiveQuestion] = useState(null)

  const images = [{ src: imgMainImg, alt: "Main Image" }]

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsVisible(false)

      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1))
        setIsVisible(true)
      }, 500)
    }, 3000)

    return () => clearInterval(intervalId)
  }, [images.length])


  return (
    <>
      <section className="soporteit-hero">
        <StickyHeader />
        <div className="soporteit-container">
          <div className="soporteit-hero-content">
            <div className="soporteit-hero-text">
              <div className="divtxts">
                <h1 className="soporteit-title">
                  Servicio de <span className="soporteit-accent">Soporte IT Outsourcing: </span>
                  Tu Socio Estratégico en Tecnología.
                </h1>
                <p className="soporteit-subtitle">
                  Con tu Departamento de Informática Outsourcing optimizas el rendimiento y la seguridad de tu
                  infraestructura IT, sin los costos elevados de un equipo interno.
                </p>

                <div className="soporteit-cta-group">
                  <div className="soporteit-cta-flex">
                    <div className="soporteit-button-container">
                      <NavLink to="/contacto" className="soporteit-button">
                        <span className="soporteit-span">Conoce más de nuestro servicio</span>
                      </NavLink>
                    </div>
                    <div className="soporteit-rating-container">
                      <h4 className="soporteit-rating-title">Cómo los clientes evalúan nuestro servicio</h4>
                      <div className="soporteit-rating">
                        <div className="soporteit-stars">
                          {[...Array(4)].map((_, i) => (
                            <span key={i} className="soporteit-star">
                              ★
                            </span>
                          ))}
                          <span className="soporteit-star-half">★</span>
                        </div>
                        <span className="soporteit-rating-text">4.5 Excelente</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="soporteit-hero-image">
              <div className={`soporteit-image-wrapper ${isVisible ? "soporteit-fade-in" : "soporteit-fade-out"}`}>
                <img
                  src={images[currentImageIndex].src || "/placeholder.svg"}
                  alt={images[currentImageIndex].alt}
                  className="soporteit-img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tabla">
        <div className="tabla-container">
          <h2 className="tabla-title">Ventajas para tu empresa de contar con un Departamento de Informática Outsourcing</h2>
          <div className="tabla-content">
            <div className="tabla-buttons tabla-buttons-left">
              {services.slice(0, 5).map((service, index) => (
                <button
                  key={index}
                  onClick={() => setActiveService(index)}
                  className={`tabla-button ${activeService === index ? "active" : ""}`}
                >
                  <div className="tabla-button-content">
                    <div className="tabla-check-icon">
                      <span className="check-symbol">✓</span>
                    </div>
                    <span>{service.title}</span>
                  </div>
                </button>
              ))}
            </div>

            <div className="tabla-description">
              {services[activeService].description.map((paragraph, index) => (
                <p key={index} className="tabla-paragraph">
                  {paragraph}
                </p>
              ))}
            </div>

            <div className="tabla-buttons tabla-buttons-right">
              {services.slice(5).map((service, index) => (
                <button
                  key={index + 5}
                  onClick={() => setActiveService(index + 5)}
                  className={`tabla-button ${activeService === index + 5 ? "active" : ""}`}
                >
                  <div className="tabla-button-content">
                    <div className="tabla-check-icon">
                      <span className="check-symbol">✓</span>
                    </div>
                    <span>{service.title}</span>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="questions-section">
        <div className="questions-container">
          <h2 className="questions-title">Preguntas Frecuentes sobre Nuestro Soporte IT</h2>
          <div className="questions-content">
            {questions.map((question, index) => (
              <div key={index} className="question-item">
                <button
                  className={`question-button ${activeQuestion === index ? "active" : ""}`}
                  onClick={() => setActiveQuestion(activeQuestion === index ? null : index)}
                >
                  <span className="question-title">{question.title}</span>
                  <span className={`question-icon ${activeQuestion === index ? "active" : ""}`}>&#x2304;</span>
                </button>
                <div className={`question-content ${activeQuestion === index ? "active" : ""}`}>
                  {question.content.map((paragraph, pIndex) => (
                    <p key={pIndex} className="question-paragraph">
                      {paragraph}
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section>
        <p> </p>
      </section>
      <ProcesoContacto />
          <div><p><br /><br /><br /><br /><br /></p></div>
    </>
  )
}

export default Main

