import { NavLink } from 'react-router-dom';
import ServiciosTercerizados from './ServiciosTercerizados';
import ServiciosBrindados from './ServiciosBrindados';
import Cuestionamiento from './Cuestionamiento';
import ProcesoContacto from './ProcesoContacto';
import StickyHeader from './StickyHeader';
import Preguntas from './Preguntas';
import Nosotros from './Nosotros';
import { useState, useEffect } from "react"

import coachimg from '../img/coach.png'
import depaimg from '../img/depa.png'
import imgMainImg from '../img/erp.png'

import Caza from '../img/Iconos/caza.png'
import Coaching from '../img/Iconos/coaching.png'
import Erp from '../img/Iconos/erp.png'
import Servicios from '../img/Iconos/servicios.png'
import logoImg from '../img/logo.png'

const Main = () => {
    const images = [
        { src: imgMainImg, alt: "Divisoria" },
        { src: coachimg, alt: "Firewall" },
        { src: depaimg, alt: "Main" },
        
      ]
    
      const [currentImageIndex, setCurrentImageIndex] = useState(0)
      const [isVisible, setIsVisible] = useState(true)
    
      useEffect(() => {
        const intervalId = setInterval(() => {
          setIsVisible(false)
    
          setTimeout(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1))
            setIsVisible(true)
          }, 500)
        }, 3000)
    
        return () => clearInterval(intervalId)
      }, [images.length])
    
      return (
        <>
          <section className="section-main mb-5">
            <StickyHeader />
            <div className="main-content-wrapper">
                
              <div className="d-flex">
                <div className="data-elementor-main2" style={{ flex: "0 0 40%" }}   >
                <span><br></br></span>  
                  <h1 className="card-title title text-start">
                    <strong style={{ color: "#000000" }}>Acelera tu crecimiento empresarial con<br></br>nuestros</strong>
                    <strong style={{ color: "#E30615" }}> servicios outsourcing</strong>
                  </h1>
    
                  <p className="elementor-subtitles">
                    <span className="elementor-red2">Servicios tercerizados para tu organización.</span>
                  </p>
                  <NavLink to="/contacto" className="btn-contactanos elementor-subtitles2">
                    <span className='divbtnmain'>
                    Agenda una llamada
                    </span>
                    
                  </NavLink>
                </div>
                <div className="data-elementor-main" style={{ flex: "0 0 60%" }}>
                  <div className="awards-container">
                    <div className={`award-item ${isVisible ? "fade-in" : "fade-out"}`}>
                      <img
                        src={images[currentImageIndex].src || "/placeholder.svg"}
                        alt={images[currentImageIndex].alt}
                        className="award-image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section-over-circle">
            
            <div className="main-content-wrapper">
                
              <div className="d-flex">
                <div style={{ flex: "0 0 10%" }}></div>
                <div className="data-elementor-main3" style={{ flex: "0 0 70%" }}   >
                  <h1 className="card-title title3 text-start">
                  
                  <p className="elementor-subtitles2 ">
                    <span className="elementor-red3" ><br></br>Más de 40 empresas han confiado en nuestros servicios outsourcing para escalar sus operaciones</span>
                  </p> 
                    
                  </h1>
    
    
                </div>
                
              </div>
            </div>
          </section>



          <section >
          
            <div className="main-content-wrapper">
                
              <div className="d-flex">
                <div className="data-elementor-main3">
                  <div className='divajustedel'>
                  <h1 className="card-title title2 text-start">
                    <strong style={{ color: "#000000" }}>Del concepto a la realidad</strong>
                  </h1>
                  <h1 className="card-title title2 text-start">
                    <strong style={{ color: "#E30615" }}>Nuestros servicios</strong>
                  </h1>
                  </div>
                </div>
              </div>

              <div className="grid-servicios-container">
                <div className="grid-servicios">
                  <NavLink to="/soporteit" className="grid-servicio-item">
                    <img src={Servicios} alt="Desarrollo de Software" className="servicio-icon" />
                    <h3>Servicios de Informática Gestionados</h3>
                    <p>Reduce costos drásticamente en tu empresa desde tu Departamento IT Outsourcing, basados en normativa ITIL y COBIT.
                    </p>
                  </NavLink>
                  
                  <div className="grid-servicio-item">
                    <img src={Erp} alt="QA y Testing" className="servicio-icon" />
                    <h3>Sistema Empresarial ERP</h3>
                    <p>Plataforma empresarial modular, cambios y personalizaciones ilimitados.</p>
                  </div>
                  
                  <div className="grid-servicio-item">
                    <img src={Coaching} alt="IA y Ciencia de Datos" className="servicio-icon" />
                    <h3>Coaching Gerencial</h3>
                    <p>Intervención profesional que ayuda a los gerentes a mejorar sus habilidades y competencias. El objetivo es que los líderes puedan dirigir de manera más efectiva y productiva. </p>
                  </div>
                  
                  <div className="grid-servicio-item">
                    <img src={Caza} alt="Desarrollo Móvil" className="servicio-icon" />
                    <h3>Caza de Talentos</h3>
                    <p>El activo más valioso para una organización es su personal, desde la convocatoria hasta la selección de talentos para tu organización.</p>
                  </div>
                </div>
              </div>
            </div>
          <div><p></p></div>
          <ProcesoContacto />
          <div><p><br /><br /><br /><br /><br /></p></div>
          </section>

{/************************** Section Service Top *************************
<ServiciosTop/>
<Nosotros/>
<ServiciosTercerizados/> 
<ServiciosBrindados/>
<Cuestionamiento/>
<Preguntas/>*/}
</>
)
}

export default Main