"use client"

import { useState, useEffect } from "react"
import { NavLink } from "react-router-dom"
import { FaBars, FaTimes, FaChevronDown } from "react-icons/fa"
import useScrollDirection from "../hooks/useScrollDirection"

import logo from "../img/yeti.png"

const StickyHeader = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [mobileServicesOpen, setMobileServicesOpen] = useState(false)
  const [headerLogoVisible, setHeaderLogoVisible] = useState(true)
  const isVisible = useScrollDirection()

  const toggleMobileMenu = () => {
    if (!mobileMenuOpen) {
      // Cuando el menú se está abriendo
      setMobileMenuOpen(true)
      setTimeout(() => setHeaderLogoVisible(false), 200) // Ocultar el logo después de 200ms
    } else {
      // Cuando el menú se está cerrando
      setHeaderLogoVisible(true)
      setTimeout(() => setMobileMenuOpen(false), 200) // Cerrar el menú después de 200ms
    }
    setMobileServicesOpen(false)
  }

  const toggleMobileServices = (e) => {
    e.stopPropagation()
    setMobileServicesOpen(!mobileServicesOpen)
  }

  useEffect(() => {
    // Asegurarse de que el logo del header esté visible cuando el menú está cerrado
    if (!mobileMenuOpen) {
      setHeaderLogoVisible(true)
    }
  }, [mobileMenuOpen])

  return (
    <header
      className={`header-elementor w-100 d-flex justify-content-between align-items-center py-4 px-4 position-fixed ${
        isVisible ? "header-visible" : "header-hidden"
      }`}
    >
      <NavLink to="/" className={`logo-container pt-2 ${headerLogoVisible ? "" : "logo-hidden"}`}>
        <figure className="m-0">
          <img src={logo || "/placeholder.svg"} alt="YETI LOGO" className="imglogoy" />
        </figure>
      </NavLink>

      {/* Menú de escritorio */}
      <ul className="d-flex px-4 pt-2 header-pc">
        <li className="menu-item">
          <button className="btn-soport elementor-subtitles d-flex align-items-center">
            <span>Servicios</span>
            <FaChevronDown className="ms-2" />
          </button>
          <div className="menu-dropdown d-flex">
            
            <div className="divtxtlarg" style={{ flex: "0 0 50%" }}>
              <span className="texto-menu">
                Nuestro compromiso es ayudar a nuestros clientes a optimizar recursos, mejorar la eficiencia y enfocarse
                en su crecimiento, mientras nos encargamos de las tareas operativas. <br></br><br></br>Con un equipo altamente capacitado
                y flexible, nos adaptamos a las necesidades específicas de cada cliente para ofrecer resultados de alta
                calidad.
              </span>
            </div>
            <div style={{ flex: "0 0 50%" }}>
              <NavLink to="/soporteit" className="menu-link">
                Servicios de Informática Gestionados
              </NavLink>
            </div>
          </div>
        </li>
 {/*<li className="menu-item">
          <button className="btn-soport elementor-subtitles d-flex align-items-center">
            <span>Acerca de Nosotros</span>
            <FaChevronDown className="ms-2" />
          </button>
          <div className="menu-dropdown">
          aqui irá el aboutus
          </div>
        </li> */}
        

        <li>
          <NavLink
            to="/contacto"
            className="btn-soport2 elementor-subtitles"
            style={{ backgroundColor: "var(--bs-yeti)", color: "white" }}
          >
            Agenda una llamada
          </NavLink>
        </li>
      </ul>

      {/* Botón de hamburguesa exterior */}
      <button onClick={toggleMobileMenu} className="icon-hamburguer" aria-label="Toggle menu">
        {mobileMenuOpen ? <FaTimes size={20} /> : <FaBars size={20} />}
      </button>

      {/* Menú móvil */}
      <div className={`mobile-menu ${mobileMenuOpen ? "open" : ""}`}>
        {/* Logo en el menú móvil */}
        <div className="mobile-menu-logo">
          <NavLink to="/" onClick={toggleMobileMenu}>
            <figure className="m-0">
              <img src={logo || "/placeholder.svg"} alt="YETI LOGO" className="imglogoy" />
            </figure>
          </NavLink>
        </div>

        {/* Botón de hamburguesa interior */}
        <button onClick={toggleMobileMenu} className="icon-hamburguer-interior" aria-label="Close menu">
          <FaTimes size={20} />
        </button>

        <ul className="mobile-menu-list">
          <li>
            <button onClick={toggleMobileServices} className="mobile-menu-item">
              Servicios
              <FaChevronDown className={`ms-2 ${mobileServicesOpen ? "rotate-180" : ""}`} />
            </button>
            <ul className={`mobile-submenu ${mobileServicesOpen ? "open" : ""}`}>
              <li>
                <NavLink to="/soporteit" className="mobile-submenu-item">
                  Servicios de Informática Gestionados
                </NavLink>
              </li>
              {/* 
              <li>
                <NavLink to="/ERP" className="mobile-submenu-item">
                  Sistema empresarial ERP
                </NavLink>
              </li>
              <li>
                <NavLink to="/coaching" className="mobile-submenu-item">
                  Coaching Gerencial
                </NavLink>
              </li>
              <li>
                <NavLink to="/talentos" className="mobile-submenu-item">
                  Caza de talentos
                </NavLink>
              </li>
              */}
            </ul>
          </li>
          {/*
          <li>
            <NavLink to="/acerca" className="mobile-menu-item">
              Acerca de Nosotros
            </NavLink>
          </li>*/}
          <li>
            <NavLink to="/contacto" className="mobile-menu-item btn-contacto">
              Agenda una llamada
            </NavLink>
          </li>
        </ul>
      </div>
    </header>
  )
}

export default StickyHeader

