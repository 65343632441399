import { AiOutlineCopyrightCircle } from 'react-icons/ai'
import { BsFacebook, BsInstagram, BsTiktok, BsTwitter, BsLinkedin, BsYoutube, } from "react-icons/bs";
import yeti from '../img/yeti.png';
import { NavLink } from 'react-router-dom';

const XLogo = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor">
      <path d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z" />
    </svg>
  )

const StickyFooter = () => {
    return(
        <>
            <footer>
                <section className="section-footer">
                    <div className="container">
                        <div className="d-flex flex-wrap gap-5 justify-content-center">
                            <div className="">
                                <p className='txttit'><strong>Ponte en Contacto<strong style={{ color: "#E30615" }}>.</strong></strong></p>
                                <div className='divbut'>
                                <NavLink to="/contacto" className="btn-contactanos4 txtbutfot ">
                                <span className='txtbutfot'>Agenda una llamada</span>
                                    
                                </NavLink></div>
                                <p> </p>

                                <p className="mb-0 txttit"><strong>Dirección<strong style={{ color: "#E30615" }}>.</strong></strong></p>
                                <p className='mb-0'><strong style={{ color: "#9e9c9c", fontSize: ".5rem",position: "relative", top: "-3px", }}>■</strong> Ciudad de Guatemala<br></br>
                                <strong style={{ color: "#9e9c9c", fontSize: ".5rem",position: "relative", top: "-3px", }}>■</strong> 595 Madison Ave 8th Floor.</p>
                                <p>New York, NY 10022</p>

                                {/*<p className="mb-0"><strong>Teléfonos</strong></p>
                                <p>(718) 569-9747</p>*/}

                                <p className="mb-0 txttit"><strong>Email<strong style={{ color: "#E30615" }}>.</strong></strong></p>
                                <p>info@yetiadvisers.com</p>
                            </div>
                            <div className="d-flex justify-content-end content-footer-logo">
                                <div>
                                    <NavLink to='/'>
                                        <picture>
                                            <img className='img-foot-logo img-fluid' src={yeti} alt='Yeti Advisers'/>
                                        </picture>
                                    </NavLink>
                                    <div>
                                        <p className='mb-0'>Sé parte de nuestra comunidad</p>
                                        <div className='d-flex gap-3'>
                                            <NavLink to="https://www.facebook.com/YetiAdvisers/" rel='noopener noreferrer nofollow' target='_blank'><span className='elementor-redes'><BsFacebook/></span></NavLink>
                                            <NavLink to="https://www.instagram.com/yetiadvisers/" rel='noopener noreferrer nofollow' target='_blank'><span className='elementor-redes'><BsInstagram/></span></NavLink>
                                            <NavLink to="https://www.tiktok.com/@yetiadvisers" rel='noopener noreferrer nofollow' target='_blank'><span className='elementor-redes'><BsTiktok/></span></NavLink>
                                            <NavLink to="https://twitter.com/yetiadvisers?lang=es" rel='noopener noreferrer nofollow' target='_blank'><span className='elementor-redes'><XLogo/></span></NavLink>
                                            <NavLink to="https://gt.linkedin.com/company/yeti-advisers" rel='noopener noreferrer nofollow' target='_blank'><span className='elementor-redes'><BsLinkedin/></span></NavLink>
                                            <NavLink to="https://www.youtube.com/channel/UC5cDlhjtOQ5nlkilEn5NW4w" rel='noopener noreferrer nofollow' target='_blank'><span className='elementor-redes'><BsYoutube/></span></NavLink>
                                        </div>
                                        <p className='mb-0'>@yetiadvisers</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="elementor-copy text-center">
                    <p className="mb-0 py-3"><span><AiOutlineCopyrightCircle/></span> 2025 YETI Advisers, S.A. Todos los derechos reservados.</p>
                </div>
            </footer>
        </>
    )
}

export default StickyFooter