"use client"

import { useState, useEffect } from "react"
import "../css/block-background.css"
// Importamos directamente las imágenes
import pcImage from "../img/banner/pc.jpg"
import phoneImage from "../img/banner/phone.jpg"

const BlockBackground = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    // Función para detectar si es un dispositivo móvil
    const checkIfMobile = () => {
      const mobileWidth = 768 // Punto de quiebre para dispositivos móviles
      setIsMobile(window.innerWidth < mobileWidth)
    }

    // Verificar al cargar el componente
    checkIfMobile()

    // Agregar listener para cambios de tamaño de ventana
    window.addEventListener("resize", checkIfMobile)

    // Deshabilitar scroll solo en esta página
    document.body.classList.add("no-scroll")

    // Limpiar el listener y restaurar el scroll cuando el componente se desmonte
    return () => {
      window.removeEventListener("resize", checkIfMobile)
      // Restaurar el scroll cuando se navegue a otra página
      document.body.classList.remove("no-scroll")
    }
  }, [])

  // Determinar qué imagen mostrar basado en el tipo de dispositivo
  const backgroundImage = isMobile ? phoneImage : pcImage

  return (
    <div className="block-background-container">
      <div
        className="block-background"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      />
    </div>
  )
}

export default BlockBackground

