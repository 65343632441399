"use client"

import { useState } from "react"
import StickyHeader from "./StickyHeader"
import response from "../helpers/sentMail"

const Contacto = () => {
  const [preSend, setPreSend] = useState(false)
  const [SendMail, setSendMail] = useState(false)
  const [allValues, setAllValues] = useState(true)
  const [inputCompany, setInputCompany] = useState("")
  const [inputMensaje, setInputMensaje] = useState("")
  const [inputPhone, setInputPhone] = useState("")
  const [inputUsers, setInputUsers] = useState("")
  const [inputName, setInputName] = useState("")
  const [inputCity, setInputCity] = useState("")
  const [inputService, setInputService] = useState("")

  const handleInputName = (e) => {
    setInputName(e.target.value)
  }
  const handleInputComapny = (e) => {
    setInputCompany(e.target.value)
    const isValidEmail = e.target.value.includes("@")
    setAllValues(isValidEmail || e.target.value === "")
  }
  const handleInputCity = (e) => {
    setInputCity(e.target.value)
  }
  const handleInputPhone = (e) => {
    setInputPhone(e.target.value)
  }
  const handleInputMensaje = (e) => {
    setInputMensaje(e.target.value)
  }
  const handleInputUsers = (e) => {
    setInputUsers(e.target.value)
  }
  const handleInputService = (e) => {
    setInputService(e.target.value)
  }

  const handleSendMail = async () => {
    const data = {
      name: inputName,
      company: inputCompany,
      city: inputCity,
      phone: inputPhone,
      mensaje: inputMensaje,
      users: inputUsers,
      service: inputService,
    }

    const res = await response(data)

    if (res.status === 200) {
      setPreSend(false)
      setSendMail(true)
      setAllValues(true)
      setTimeout(() => {
        setSendMail(false)
      }, 3000)
    } else {
      setSendMail(false)
    }
  }

  const handleValidation = () => {
    // Check if email contains @ symbol
    const isValidEmail = inputCompany.includes("@")

    // Check if radiosUsers is required and selected
    const isRadiosUsersRequired = inputService === "Soporte IT" || inputService === "Sistema Empresarial"
    const isRadiosUsersSelected = isRadiosUsersRequired ? inputUsers !== "" : true

    if (
      inputName !== "" &&
      inputCompany !== "" &&
      isValidEmail &&
      inputCity !== "" &&
      inputPhone !== "" &&
      inputService !== "" &&
      isRadiosUsersSelected
    ) {
      setAllValues(true)

      try {
        if (typeof window !== 'undefined' && 
            window.gtag_report_conversion && 
            typeof window.gtag_report_conversion === 'function') {
          window.gtag_report_conversion()
        }
      } catch (error) {
        console.log('Error al llamar gtag_report_conversion:', error)
      }

    } else {
      setAllValues(false)
      setTimeout(() => {
        setAllValues(true)
      }, 3000)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (allValues) {
      setPreSend(true)
      await handleSendMail()
      setInputCompany("")
      setInputMensaje("")
      setInputPhone("")
      setInputUsers("")
      setInputName("")
      setInputCity("")
    }
  }

  return (
    <>
      <section className="elementor-other-header">
        <StickyHeader />
      </section>
      {/*  <div className="d-flex">*/}
      <div className="main-content-wrapper">
        <div className=" container">
          <div className="d-flex justify-content-center span-cotiza">
            <span className="span-cotiza2">
              <br></br>
              <br></br>
              <br></br>Gracias por tu interés en YETI Advisers<br></br>
              <span className="span-cotiza3">
                Nos gustaría hacerte algunas preguntas para comprender mejor tus necesidades de servicios outsourcing.
                <br></br>
              </span>
            </span>
          </div>
          <div className="d-flex justify-content-center">
            <form onSubmit={handleSubmit} style={{ flex: "0 0 80%" }}>
              <div className="mb-3">
                <span>
                  <br></br>
                </span>
              </div>
              <div className="mb-3">
                <label htmlFor="inputName" className="form-label elementor-label">
                  Tu nombre <span className="elementor-red">*</span>
                </label>
                <input
                  type="text"
                  className="form-control elementor-input"
                  id="inputName"
                  placeholder="Tu nombre "
                  value={inputName}
                  onChange={handleInputName}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="inputCompany" className="form-label elementor-label">
                  Tu e-mail de trabajo <span className="elementor-red">*</span>
                </label>
                <input
                  type="text"
                  className={`form-control elementor-input ${inputCompany && !inputCompany.includes("@") ? "border-danger" : ""}`}
                  id="inputCompany"
                  placeholder="Tu e-mail de trabajo"
                  value={inputCompany}
                  onChange={handleInputComapny}
                />
                {inputCompany && !inputCompany.includes("@") && (
                  <div className="text-danger mt-1 small">Por favor ingresa un correo electrónico válido</div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="inputCity" className="form-label elementor-label">
                  Ciudad en donde te ubicas <span className="elementor-red">*</span>
                </label>
                <input
                  type="text"
                  className="form-control elementor-input"
                  id="inputCity"
                  placeholder="Mixco, Villa Nueva, Guatemala"
                  value={inputCity}
                  onChange={handleInputCity}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="inputPhone" className="form-label elementor-label">
                  Teléfono <span className="elementor-red">*</span>
                </label>
                <input
                  type="tel"
                  className="form-control elementor-input"
                  id="inputPhone"
                  placeholder="0000-0000"
                  value={inputPhone}
                  onChange={handleInputPhone}
                />
              </div>

              <div className="form-container">
                <fieldset className="mb-3">
                  <legend className="form-label elementor-label">
                    Selecciona el servicio que necesitas <span className="elementor-red">*</span>
                  </legend>
                  <div className="service-options">
                    <div className="form-check mb-3 service-option">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="serviceType"
                        id="serviceType1"
                        value="Soporte IT"
                        checked={inputService === "Soporte IT"}
                        onChange={handleInputService}
                      />
                      <label className="form-check-label elementor-label fw-medium" htmlFor="serviceType1">
                        Soporte IT
                      </label>
                      <div className="service-tooltip">Departamento IT Outsourcing para tu organización</div>
                    </div>

                    <div className="form-check mb-3 service-option">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="serviceType"
                        id="serviceType2"
                        value="Sistema Empresarial"
                        checked={inputService === "Sistema Empresarial"}
                        onChange={handleInputService}
                      />
                      <label className="form-check-label elementor-label fw-medium" htmlFor="serviceType2">
                        Sistema Empresarial
                      </label>
                      <div className="service-tooltip">ERP y desarrollo de software para tu empresa</div>
                    </div>

                    <div className="form-check mb-3 service-option">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="serviceType"
                        id="serviceType3"
                        value="Coaching Gerencial "
                        checked={inputService === "Coaching Gerencial "}
                        onChange={handleInputService}
                      />
                      <label className="form-check-label elementor-label fw-medium" htmlFor="serviceType3">
                        Coaching Gerencial
                      </label>
                      <div className="service-tooltip">
                        Nos enfocamos en la conexión y el manejo eficaz de tu fuerza laboral
                      </div>
                    </div>
                    <div className="form-check mb-3 service-option">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="serviceType"
                        id="serviceType4"
                        value="Reclutamiento y Selección"
                        checked={inputService === "Reclutamiento y Selección"}
                        onChange={handleInputService}
                      />
                      <label className="form-check-label elementor-label fw-medium" htmlFor="serviceType4">
                        Reclutamiento y Selección
                      </label>
                      <div className="service-tooltip">Integración de fuerza laboral competente en tu empresa</div>
                    </div>
                  </div>
                </fieldset>

                {(inputService === "Soporte IT" || inputService === "Sistema Empresarial") && (
                  <fieldset className="user-options">
                    <legend className="form-label elementor-label">
                      {inputService === "Soporte IT" ? "Número de ordenadores" : "Número de usuarios"}{" "}
                      <span className="elementor-red">*</span>
                    </legend>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="radiosUsers"
                        id="radiosUsers1"
                        value="25"
                        checked={inputUsers === "25"}
                        onChange={handleInputUsers}
                      />
                      <label className="form-check-label elementor-label2 fw-medium" htmlFor="radiosUsers1">
                        10 a 25
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="radiosUsers"
                        id="radiosUsers2"
                        value="40"
                        checked={inputUsers === "40"}
                        onChange={handleInputUsers}
                      />
                      <label className="form-check-label elementor-label2 fw-medium" htmlFor="radiosUsers2">
                        26 a 40
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="radiosUsers"
                        id="radiosUsers3"
                        value="55"
                        checked={inputUsers === "55"}
                        onChange={handleInputUsers}
                      />
                      <label className="form-check-label elementor-label2 fw-medium" htmlFor="radiosUsers3">
                        41 a 55
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="radiosUsers"
                        id="radiosUsers4"
                        value="56"
                        checked={inputUsers === "56"}
                        onChange={handleInputUsers}
                      />
                      <label className="form-check-label elementor-label2 fw-medium" htmlFor="radiosUsers4">
                        Más de 56
                      </label>
                    </div>
                    {inputUsers === "" && (
                      <div className="text-danger mt-1 small">
                        {inputService === "Soporte IT"
                          ? "Por favor selecciona el número de ordenadores"
                          : "Por favor selecciona el número de usuarios"}
                      </div>
                    )}
                  </fieldset>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="inputMensaje" className="form-label elementor-label">
                  ¿Como podemos ayudarte?
                </label>
                <textarea
                  type="text"
                  className="form-control elementor-input"
                  id="inputMensaje"
                  placeholder="Si lo deseas, escribe acá tu solicitud"
                  value={inputMensaje}
                  onChange={handleInputMensaje}
                ></textarea>
              </div>
              {/* 
                            <fieldset className="mb-3">
                                <legend className="form-label elementor-label">Número de usuarios en su Organización <span className="elementor-red">*</span></legend>
                                <div className="form-check mb-3">
                                    <input className="form-check-input" type="radio" name="radiosUsers" id="radiosUsers1" value="15" checked={inputUsers === '15'} onChange={handleInputUsers}/>
                                    <label className="form-check-label elementor-label fw-medium" htmlFor="radiosUsers1">1 a 15</label>
                                </div>
                                <div className="form-check mb-3">
                                    <input className="form-check-input" type="radio" name="radiosUsers" id="radiosUsers2" value="35" checked={inputUsers === '35'} onChange={handleInputUsers}/>
                                    <label className="form-check-label elementor-label fw-medium" htmlFor="radiosUsers1">16 a 35</label>
                                </div>
                                <div className="form-check mb-3">
                                    <input className="form-check-input" type="radio" name="radiosUsers" id="radiosUsers3" value="50" checked={inputUsers === '50'} onChange={handleInputUsers}/>
                                    <label className="form-check-label elementor-label fw-medium" htmlFor="radiosUsers1">36 a 50</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="radiosUsers" id="radiosUsers4" value="51" checked={inputUsers === '51'} onChange={handleInputUsers}/>
                                    <label className="form-check-label elementor-label fw-medium" htmlFor="radiosUsers1">Más de 50</label>
                                </div>
                            </fieldset>*/}

              <div
                className={`alert alert-danger d-flex align-items-center ${!allValues ? "show-incomplete" : "d-none"}`}
                role="alert"
              >
                <div>
                  {!inputCompany.includes("@") && inputCompany !== ""
                    ? "Por favor ingresa un correo electrónico válido"
                    : inputService === "Soporte IT" && inputUsers === ""
                      ? "Por favor selecciona el número de ordenadores"
                      : inputService === "Sistema Empresarial" && inputUsers === ""
                        ? "Por favor selecciona el número de usuarios"
                        : "No se han completado todos los campos... ¡Por favor completa todos los campos!"}
                </div>
              </div>

              <div className={`alert alert-warning ${preSend ? "show-incoming" : "d-none"}`} role="alert">
                Enviando correo...
              </div>

              <div className={`alert alert-success ${SendMail ? "show-success" : "d-none"}`} role="alert">
                Gracias, con gusto nos pondremos en contacto
              </div>

              <button onClick={handleValidation} className={`elementor-btn-cotiza ${preSend ? "d-none" : ""}`}>
                Enviar
              </button>
            </form>
          </div>
          <div>
            <span>
              <br></br>
              <br></br>
              <br></br>
            </span>
          </div>
        </div>
      </div>
      {/* </div>*/}
    </>
  )
}

export default Contacto

