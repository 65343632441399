import persona from "../img/Contactoi/persona.png"
import iconos from "../img/Contactoi/iconos.png"
import "../css/ProcesoContacto.css"

const ProcesoContacto = () => {
  return (
    <div className="proceso-contacto-main">
      {/* Left column - 40% */}
      <div className="proceso-left">
        <h2 className="proceso-heading">
          Nuestro proceso.
          <br />
          Sencillo,
          <br />
          fluido y<br />
          optimizado<strong style={{ color: "#E30615" }}>.</strong>
        </h2>
        <div className="proceso-image-container">
          <img src={persona || "/placeholder.svg"} alt="Profesional" className="proceso-image" />
        </div>
      </div>

      {/* Right column - 60% */}
      <div className="proceso-right">
        {/* Icons column - 30% of right side */}
        <div className="proceso-icons">
          <img src={iconos || "/placeholder.svg"} alt="Iconos de proceso" className="proceso-icons-image" />
        </div>

        {/* Steps column - 70% of right side */}
        <div className="proceso-steps">
          {/* Step 1 */}
          <div className="proceso-step">
            <p className="proceso-step-number">PASO 1</p>
            <h3 className="proceso-step-title">Llamada de exploración</h3>
            <p className="proceso-step-description">
              Cuéntanos más sobre tu negocio en una llamada de exploración. Analizaremos los requisitos, los criterios
              de éxito, el cronograma, <br />
              el presupuesto y las habilidades necesarias para ayudarte.
            </p>
          </div>

          {/* Step 2 */}
          <div className="proceso-step">
            <p className="proceso-step-number">PASO 2</p>
            <h3 className="proceso-step-title">Propuesta de solución personalizada y seguimiento</h3>
            <p className="proceso-step-description">
              Elaboraremos una propuesta económica con base al escenario de tu organización y abordaremos a detalle cada
              solución recomendada.
            </p>
          </div>

          {/* Step 3 */}
          <div className="proceso-step">
            <p className="proceso-step-number">PASO 3</p>
            <h3 className="proceso-step-title">Alianza comercial</h3>
            <p className="proceso-step-description">
              Nuestro equipo se une al tuyo y comenzamos a trabajar, te brindamos feedback medible de nuestros servicios
              que te permiten medir el retorno de tu inversión.
            </p>
          </div>

          {/* CTA Button */}

          <div className="proceso-cta">
            <a href="/contacto" className="proceso-cta-button">
              <span style={{ color: "white" }}>Agenda una llamada</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProcesoContacto

