"use client"

import { useState, useEffect } from "react"

const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState("up")
  const [prevOffset, setPrevOffset] = useState(0)
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    const toggleHeader = () => {
      const currentOffset = window.pageYOffset
      const isScrollingDown = currentOffset > prevOffset

      if (currentOffset > 30) {
        setVisible(!isScrollingDown)
      } else {
        setVisible(true)
      }

      setScrollDirection(isScrollingDown ? "down" : "up")
      setPrevOffset(currentOffset)
    }

    window.addEventListener("scroll", toggleHeader)

    return () => {
      window.removeEventListener("scroll", toggleHeader)
    }
  }, [prevOffset])

  return visible
}

export default useScrollDirection

