"use client"

import { NavLink } from "react-router-dom"
import StickyHeader from "./StickyHeader"
import { useState, useEffect } from "react"
import "../css/erp.css"

import imgMainImg from "../img/img_main.jpg"

const services = [
  {
    title: "Custom React Web Development",
    description: [
      "Enjoy fast, efficient deployment and application scalability by harnessing the power of React.",
      "Our developers create component-driven architecture to manage state and props for seamless data flow. We also use industry-standard testing frameworks like Jest for rigorous unit and integration testing to ensure software quality and reliability.",
    ],
  },
  {
    title: "SaaS Application Build and Migration",
    description: [
      "What do Slack, Asana, and Hubspot have in common? They all rely on React. React is popular in the SaaS space because it's so user-friendly and versatile.",
      "At BairesDev, we excel at handling projects of various sizes and levels of complexity. From using Context API and GraphQL for smaller applications to deploying REST APIs and Redux for larger projects, you can rely on us to create scalable solutions that adhere to the strictest security standards.",
    ],
  },
  {
    title: "Mobile App Development",
    description: [
      "Get beautiful, high-performance apps that look and feel like native Android or iOS applications.",
      "That's the beauty of building apps with React Native, a framework that combines JavaScript's efficiency with React's UI-focused capabilities.",
      "And since everything is built on a single codebase, you'll enjoy faster iteration and deployment as well as easier app maintenance in the future.",
    ],
  },
  {
    title: "React UI/UX Design",
    description: [
      "Partner with our expert UX/UI designers to create attractive and interactive applications tailored to your needs.",
      "We leverage React's modular nature, and advanced libraries such as Material-UI and Ant Design (for style components) as well as Framer Motion and React Spring (for animations) to ensure a rich and intuitive user experience.",
    ],
  },
  {
    title: "React App Support & Maintenance",
    description: [
      "Building the app is just half the battle. Reduce downtime, minimize bugs, and ensure high performance with our dedicated React app support and maintenance services. We also handle ongoing security patching and compatibility checking.",
      "Our deep understanding of the React ecosystem and connection to the community ensures your application is always up-to-date in the ever-evolving tech landscape.",
    ],
  },
]

const Main = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [isVisible, setIsVisible] = useState(true)

  const images = [{ src: imgMainImg, alt: "Main Image" }]

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsVisible(false)

      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1))
        setIsVisible(true)
      }, 500)
    }, 3000)

    return () => clearInterval(intervalId)
  }, [images.length])

  return (
    <>
      <section className="erp-hero">
        <StickyHeader />
        <div className="erp-container">
          <div className="erp-hero-content">
            <div className="erp-hero-text">
              <h1 className="erp-title">
                Scale your <span className="erp-accent">React</span> development with nearshore talent.
              </h1>
              <p className="erp-subtitle">
                Our React development services already power over 200 active engagements. We typically land our teams
                within 2 weeks, so you can start shipping top quality software, fast.
              </p>
              <div className="erp-cta-group">
                <NavLink to="/contacto" className="erp-button">
                  Accelerate Your React Development
                </NavLink>
                <div className="erp-rating">
                  <div className="erp-stars">
                    {[...Array(5)].map((_, i) => (
                      <span key={i} className="erp-star">
                        ★
                      </span>
                    ))}
                  </div>
                  <span className="erp-rating-text">4.4 Excellent</span>
                </div>
              </div>
            </div>
            <div className="erp-hero-image">
              <div className={`erp-image-wrapper ${isVisible ? "erp-fade-in" : "erp-fade-out"}`}>
                <img
                  src={images[currentImageIndex].src || "/placeholder.svg"}
                  alt={images[currentImageIndex].alt}
                  className="erp-img"
                />
              </div>
            </div>
          </div>

          
        </div>
      </section>

      <section className="erp-services">
        <div className="erp-container">
          <h2 className="erp-services-title">React Development Services We Provide</h2>
          <div className="erp-services-grid">
            {services.map((service, index) => (
              <div key={service.title} className="erp-service-item">
                <div className="erp-service-header">
                  <div className="erp-service-icon">
                    <svg viewBox="0 0 24 24" fill="none" className="erp-check-icon">
                      <path
                        d="M20 6L9 17L4 12"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <h3 className="erp-service-title">{service.title}</h3>
                </div>
                <div className="erp-service-content">
                  {service.description.map((paragraph, i) => (
                    <p key={i} className="erp-service-description">
                      {paragraph}
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default Main

